// extracted by mini-css-extract-plugin
export var body = "article-module--body--3Qfn4";
export var button = "article-module--button--dGuIw";
export var buttonContainer = "article-module--buttonContainer--VoB6C";
export var date = "article-module--date--ymBWE";
export var icon = "article-module--icon--CTl9D";
export var image = "article-module--image--mZh1P";
export var section = "article-module--section--smh6L";
export var show = "article-module--show--b2dNA";
export var subheading = "article-module--subheading--nt0jN";
export var title = "article-module--title--rdEBX";