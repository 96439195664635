import React, { useState, useContext, useRef, useEffect } from 'react'
import { Context } from '../../context'
import Block from '../../settings/blog.json'
import Layout from '../../layout/layout'
import FooterImage from '../../layout/main/components/footerImage'
import SimpleForm from '../../layout/main/components/simpleForm'
import * as styles from './article.module.scss'
import parse from 'html-react-parser'
import { compareDate, Content, filterResults, FormatDate, SearchResults } from '../../utilities'
import { Link } from 'gatsby'
import { marked } from 'marked'

type articleProps = {
  pageContext: {
    title: string,
    image?: string,
    date: string,
    body: string,
    subheading?: string,
    articles?: any[],
    searchIndexes? : [any]
  }
}

export default ({ pageContext: { title, image, date, body, subheading, articles = [], searchIndexes } }: articleProps) => {
  const [searchResults, setSearchResults] = useState([]);
  const context = useContext(Context);
  const [show, setShow] = useState(false);
  articles.sort(compareDate)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 100)
  },[])

  useEffect(() => {
    if (searchIndexes && context) {
      const results = filterResults(searchIndexes, context);
      if (results)
        context.setMenuOpen(false);
      setSearchResults(results);
    }
  }, [context && context.search])

  return (
    <Layout title={title} >
      <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
        <div className={styles.buttonContainer}>
          <Link to={`/happenings#articles`} className={styles.button}>
          <span><img src='/button.svg' className={styles.icon} /></span>
            <Content text='Go back' />
          </Link>
        </div>
        <p className={styles.date}><Content text='Posted' /> <span><FormatDate date={date} /></span></p>
        <h2 className={styles.title}>
          <Content text={title} />
        </h2>
        <div className={styles.subheading}>
          {subheading && <Content text={marked.parse(subheading ?? "")} />}
        </div>
        {image &&
        <img src={image} className={styles.image} />}
        <div className={styles.body}>
          <Content text={marked.parse(body ?? "")} />
        </div>
      </section>
      <SimpleForm block={Block} scroll={context && context.scroll} />
      <FooterImage block={Block} scroll={context && context.scroll} />
      <SearchResults results={searchResults} />
    </Layout>
  )
}